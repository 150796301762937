import React from "react";
import style from "./Info.module.css";

export default function Info() {
  return (
    <div className={style.Page}>
      <section>
        <span>Chiara Avagliano is an Italian artist based in London.</span>
        <span>
          {" "}
          After moving to the UK in 2013, she graduated in Photography from
          London College of Communication in 2018.
        </span>
        <span>
          {" "}
          Interested in the relationship between text, image and object, she
          mixes different media, blurring the boundaries of her practice.
        </span>{" "}
        <span>
          {" "}
          Her work is inspired by natural science and informed by her personal
          background. In an attempt to lessen the distance between science and
          art, she blends the two elements in a poetic narrative. She is
          currently undertaking a MA in Translation and Culture with Technology
          at UCL.
        </span>
      </section>
      <section>
        <strong>Awards</strong>
        <ul>
          <li>
            The Photographers’ Gallery - New Talent Award 2019 – Selected Artist
          </li>
          <li>Photograd – Highly commended 2018 Photography Graduate</li>
          <li>MACK First Book Award 2017 – Nominee</li>
        </ul>
      </section>
      <section>
        <strong>Group Exhibitions</strong>

        <ul>
          <li>
            Taylor Wessing x Circulation(s), Paris - January 2021 - ongoing
          </li>
          <li>
            Bratislava, OFF Festival - 9 to 22 November 2020 - Invited Artist
          </li>
          <li>
            Paris, Festival Circulation(s) - Group Exhibition at 104 Paris - 14
            March to 10th May - extended June 5 to August 9 2020 - Invited
            Artist
          </li>
          <li>
            The Photographers’ Gallery - TPG New Talent - 14 June 2019 - 6
            October 2019
          </li>
          <li>
            Photomonth – East London Photography Festival - Cass School of Art,
            November 2018
          </li>
          <li>40 Months / in Exile – The New Cross House 2017</li>{" "}
          <li>Offprint – Tate Modern 2017</li>
        </ul>
      </section>
      <section>
        <strong>Features</strong>
        <ul>
          <li>La Voce - Carpi (print) - February 2020</li>
          <li>Ad Magazine - January 2020</li>
          <li>La Repubblica - November 2019</li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.lensculture.com/articles/the-photographers-gallery-elastic-forms-elastic-visions?fbclid=IwAR3wTD-7YL6B2EfsRENJ_hqB8XUzSi-oXHilMBDYhuxUsTFDZS9xNl5tk34"
            >
              LensCulture - October 2019
            </a>
          </li>
          <li>TPG - Instagram Takeover August 2019</li>
          <li>CR Creative Review - June 2019</li>
          <li>Tank Magazine - June 2019</li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.londonlive.co.uk/news/2019-06-19/rising-talent-at-the-photographers-gallery?fbclid=IwAR3faBYQVC09-knilsGyXKKjzNzRN9nzEugPeIkhWcMGeOAKJpuzuMKz6dM"
            >
              London Live - June 2019
            </a>
          </li>
          <li>Hero Magazine – June 2019</li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.arts.ac.uk/colleges/london-college-of-communication/stories/ba-hons-photography-graduate-selected-for-the-photographers-gallery-new-talent-mentoring-programme-and-exhibition"
            >
              University of The Arts London, London College of Communication -
              May 2019
            </a>
          </li>
          <li>Creative Boom – April 2019</li>
          <li>Spectrum Photographic – Instagram Takeover</li>{" "}
          <li>PGZ2018 – Photograd Zine 2018</li>{" "}
          <li>Photograd – Online feature</li>
        </ul>
      </section>
      <section>
        <strong>Education</strong>
        <ul>
          <li>
            2021-2023 MA Translation and Culture with Technology, UCL
            (Distinction)
          </li>{" "}
          <li>
            2015-18 BA (Hons) Photography, London College Of Communication –
            University of the Arts London (First Class Honours)
          </li>{" "}
          <li>
            2013-14 Access Course to Higher Education in Art & Design –
            Photography - City of Westminster College (Distinction)
          </li>
        </ul>
      </section>
    </div>
  );
}
